export default function Logo() {
	return (
		<div>
			<svg
				version="1.1"
				id="Layer_1"
				viewBox="0 0 100.2 15.3"
				className="h-5 fill-foreground"
			>
				<g>
					<path
						d="M7.1,10.9c0,1.6-1.3,2.6-3,2.6c-1.1,0-1.8-0.6-1.8-1.6c0-0.9,0.5-1.4,1.6-1.6C5,10,6.1,10,7.1,9.5V10.9z M9.4,12.3V6.9
  c0-2.1-1.5-3.3-4.4-3.3C2.1,3.6,0.4,5,0.4,7.2v0.2h2.2V7.3c0-1.1,0.8-1.7,2.2-1.7c1.5,0,2.2,0.5,2.2,1.5c0,1-0.5,1-2.4,1.3
  C1.7,8.8,0,9.5,0,12.1c0,2,1.3,3.2,3.5,3.2c1.4,0,2.8-0.6,3.6-1.8c0,1.1,0.7,1.7,2.1,1.7c0.4,0,0.8-0.1,1.2-0.2v-1.7
  c-0.2,0-0.3,0-0.5,0C9.4,13.4,9.4,13,9.4,12.3"
					></path>
					<path d="M14.3,5.9V4h-2.2v11h2.3V9c0-1.9,0.9-2.8,2.8-2.8h0.6V3.8c-0.3,0-0.5,0-0.5,0C16,3.8,14.9,4.5,14.3,5.9"></path>
					<path
						d="M23.5,5.6c1.4,0,2.3,0.8,2.4,2.1h2.3c-0.1-2.6-2-4.1-4.7-4.1c-3.2,0-5.1,2.2-5.1,5.9c0,3.6,1.9,5.8,4.9,5.8
  c2.9,0,4.8-1.7,5-4.4h-2.2c-0.2,1.5-1.1,2.3-2.5,2.3c-1.7,0-2.7-1.3-2.7-3.8C20.7,6.9,21.7,5.6,23.5,5.6"
					></path>
					<path
						d="M35.2,3.7c-1.5,0-2.6,0.6-3.4,1.7V0h-2.3v15h2.3V8.5c0-1.7,1.1-2.8,2.7-2.8c1.5,0,2.1,0.8,2.1,2.5v0.5V15H39V8.2
  c0-0.5,0-0.8,0-1C39,4.9,37.6,3.7,35.2,3.7"
					></path>
				</g>
				<rect x="41.3" y="4" width="2.3" height="11"></rect>
				<rect x="41.3" width="2.3" height="2.4"></rect>
				<g>
					<path
						d="M48.6,1h-2.3v11.5c0,1.9,0.6,2.6,2.5,2.6c0.5,0,1-0.1,1.6-0.1v-1.9l-0.5,0c-0.1,0-0.3,0-0.5,0c-0.8,0-0.9-0.3-0.9-1V5.7
  h1.8V4h-1.8V1z"
					></path>
					<path
						d="M53.7,8.4c0.1-1.8,1.1-2.8,2.6-2.8c1.6,0,2.6,1,2.6,2.8H53.7z M56.4,3.6c-3.2,0-5.1,2.2-5.1,5.8c0,3.7,2,5.9,5.1,5.9
  c2.5,0,4.5-1.5,4.9-3.8h-2.3c-0.3,1.1-1.2,1.8-2.4,1.8c-1.7,0-2.8-1.1-2.8-3.2h7.7c0-0.3,0-0.6,0-0.8C61.4,5.7,59.4,3.6,56.4,3.6"
					></path>
					<path
						d="M67.6,5.6c1.4,0,2.2,0.8,2.4,2.1h2.3c-0.1-2.6-2-4.1-4.7-4.1c-3.2,0-5.1,2.2-5.1,5.9c0,3.6,1.9,5.8,4.9,5.8
  c2.9,0,4.8-1.7,5-4.4H70c-0.2,1.5-1.1,2.3-2.5,2.3c-1.7,0-2.7-1.3-2.7-3.8C64.8,6.9,65.8,5.6,67.6,5.6"
					></path>
					<path
						d="M76,1h-2.3v11.5c0,1.9,0.6,2.6,2.5,2.6c0.5,0,1-0.1,1.6-0.1v-1.9l-0.5,0c-0.1,0-0.3,0-0.5,0c-0.8,0-0.9-0.3-0.9-1V5.7h1.8
  V4H76V1z"
					></path>
					<path
						d="M86.7,10.2c0,2-1,3.1-2.8,3.1c-1.6,0-2-0.9-2-2.5V4h-2.3v7c0,1.2,0,2.1,0.6,2.9c0.6,0.9,1.7,1.4,3.3,1.4
  c1.4,0,2.7-0.6,3.4-1.8V15H89V4h-2.3V10.2z"
					></path>
					<path
						d="M96.5,8.4l-1.4-0.3c-1.2-0.3-1.8-0.6-1.8-1.4c0-0.7,0.6-1.2,1.9-1.2c1.4,0,2.3,0.6,2.3,1.7h2.3c0-2.1-1.7-3.5-4.5-3.5
  c-2.7,0-4.3,1.4-4.3,3.4c0,1.8,1.1,2.6,3.4,3.1l1.6,0.4c1.1,0.3,1.7,0.5,1.7,1.3c0,0.9-0.8,1.5-2.2,1.5c-1.6,0-2.5-0.6-2.6-2h-2.3
  c0,2.5,1.7,3.9,4.7,3.9c3,0,4.8-1.4,4.8-3.7C100,9.6,98.8,9,96.5,8.4"
					></path>
				</g>
			</svg>
		</div>
	);
}
